html,
body {
  margin: 0;
  padding: 0;
}

.fade-in-slide-up {
  animation: fadeInSlideUp 0.7s forwards;
}

.fade-out-slide-down {
  animation: fadeOutSlideDown 0.7s forwards;
}

@keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutSlideDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

.app-mobile {
  height: calc(var(--app-height) - 7.3rem);
  overflow: hidden;
}

.app-mobile-little {
  height: calc(var(--app-height) - 7.5rem);
  overflow: hidden;
}

.app-mobile-little-x2-ios {
  height: calc(var(--app-height) - 6.9rem);
  overflow: hidden;
}

.app-mobile-little-x2-android {
  height: calc(var(--app-height) - 5.6rem);
  overflow: hidden;
}

.app-short-with-footer {
  height: calc(var(--app-height) - 2.6rem);
  overflow: hidden;
}

.app-short-without-footer {
  height: calc(var(--app-height));
  overflow: hidden;
}

.app {
  height: calc(var(--app-height) - 8.2rem);
  overflow: hidden;
}

input:-webkit-autofill {
  transition-delay: 9999s
}

.apple-en-button-mobile-for-android {
  height: 6vh;
  width: 39vw;
}

.apple-en-button-mobile-little-for-android {
  height: 5.9vh;
  width: 35.75vw;
}

.apple-en-button-mobile-little-x2-for-android {
  height: 6.5vh;
  width: 27.5vw;
}

.apple-fr-button-mobile-for-android {
  height: 6vh;
  width: 39vw;
}

.apple-fr-button-mobile-little-for-android {
  height: 6vh;
  width: 34vw;
}

.apple-fr-button-mobile-little-x2-for-android {
  height: 6.5vh;
  width: 27.5vw;
}

.android-en-button-mobile-little-for-android {
  height: 6vh;
  width: 35.5vw;
}

.android-en-button-mobile-little-x2-for-android {
  height: 6.5vh;
  width: 26.5vw;
}

.android-en-button-mobile-for-android {
  height: 6vh;
  width: 36.5vw;
}

.android-fr-button-mobile-little-for-android {
  height: 6vh;
  width: 34.5vw;
}

.android-fr-button-mobile-little-x2-for-android {
  height: 6.5vh;
  width: 26.5vw;
}

.android-fr-button-mobile-for-android {
  height: 6vh;
  width: 38.5vw;
}

.apple-en-button-mobile-little-x2-for-ios {
  height: 6vh;
  width: 30vw;
}

.apple-en-button-mobile-little-for-ios {
  height: 6vh;
  width: 35.5vw;
}

.apple-fr-button-mobile-little-x2-for-ios {
  height: 6vh;
  width: 30vw;
}

.apple-fr-button-mobile-little-for-ios {
  height: 6vh;
  width: 35.5vw;
}

.apple-en-button-mobile-for-ios {
  height: 6vh;
  width: 36.75vw;
}

.apple-fr-button-mobile-for-ios {
  height: 6vh;
  width: 36.75vw;
}

.android-en-button-mobile-little-x2-for-ios {
  height: 6vh;
  width: 30vw;
}

.android-en-button-mobile-little-for-ios {
  height: 6vh;
  width: 35.5vw;
}

.android-fr-button-mobile-little-x2-for-ios {
  height: 6vh;
  width: 30vw;
}

.android-fr-button-mobile-little-for-ios {
  height: 6vh;
  width: 35.5vw;
}

.android-en-button-mobile-for-ios {
  height: 6vh;
  width: 36.25vw;
}

.android-fr-button-mobile-for-ios {
  height: 6vh;
  width: 36.25vw;
}

.android-button {
  width: 9vw;
  height: 5.05vh;
}

.apple-fr-button {
  width: 9.25vw;
  height: 5vh;
}

.apple-en-button {
  width: 9.25vw;
  height: 5vh;
}
